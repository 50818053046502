// Generated by Framer (d2515d1)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["c7gh2KS9W", "g8hHAXyM2"];

const serializationHash = "framer-DaXne"

const variantClassNames = {c7gh2KS9W: "framer-v-h8i2ue", g8hHAXyM2: "framer-v-fg70fo"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {Desktop: "c7gh2KS9W", Mobile: "g8hHAXyM2"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "c7gh2KS9W"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "c7gh2KS9W", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Image {...restProps} {...gestureHandlers} background={{alt: "", fit: "fill", pixelHeight: 492, pixelWidth: 1756, sizes: componentViewport?.width || "100vw", src: "https://framerusercontent.com/images/ofNQ9g6Oxv0mgNaFyqQ7kPC70U.png", srcSet: "https://framerusercontent.com/images/ofNQ9g6Oxv0mgNaFyqQ7kPC70U.png?scale-down-to=512 512w,https://framerusercontent.com/images/ofNQ9g6Oxv0mgNaFyqQ7kPC70U.png?scale-down-to=1024 1024w,https://framerusercontent.com/images/ofNQ9g6Oxv0mgNaFyqQ7kPC70U.png 1756w"}} className={cx(serializationHash, ...sharedStyleClassNames, "framer-h8i2ue", className, classNames)} data-framer-name={"Desktop"} layoutDependency={layoutDependency} layoutId={"c7gh2KS9W"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({g8hHAXyM2: {"data-framer-name": "Mobile"}}, baseVariant, gestureVariant)}><motion.div className={"framer-uqdzfv"} layoutDependency={layoutDependency} layoutId={"foI0KLWui"} style={{backgroundColor: "rgb(7, 12, 20)", borderBottomLeftRadius: "50%", borderBottomRightRadius: "50%", borderTopLeftRadius: "50%", borderTopRightRadius: "50%", filter: "blur(34px)", WebkitFilter: "blur(34px)"}}/><motion.div className={"framer-rpjjcf"} layoutDependency={layoutDependency} layoutId={"c5p4W_GZd"} style={{backgroundColor: "rgb(7, 12, 20)", borderBottomLeftRadius: "50%", borderBottomRightRadius: "50%", borderTopLeftRadius: "50%", borderTopRightRadius: "50%", filter: "blur(34px)", WebkitFilter: "blur(34px)"}}/></Image></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-DaXne.framer-vz58an, .framer-DaXne .framer-vz58an { display: block; }", ".framer-DaXne.framer-h8i2ue { height: 432px; overflow: visible; position: relative; width: 1440px; }", ".framer-DaXne .framer-uqdzfv { bottom: -84px; flex: none; height: 300px; left: -114px; overflow: hidden; position: absolute; width: 300px; will-change: var(--framer-will-change-override, transform); z-index: 1; }", ".framer-DaXne .framer-rpjjcf { bottom: -84px; flex: none; height: 300px; overflow: hidden; position: absolute; right: -114px; width: 300px; will-change: var(--framer-will-change-override, transform); z-index: 1; }", ".framer-DaXne.framer-v-fg70fo.framer-h8i2ue { height: 135px; width: 450px; }", ".framer-DaXne.framer-v-fg70fo .framer-uqdzfv { bottom: -54px; height: 150px; left: -84px; width: 150px; }", ".framer-DaXne.framer-v-fg70fo .framer-rpjjcf { bottom: -54px; height: 150px; right: -84px; width: 150px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 432
 * @framerIntrinsicWidth 1440
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"g8hHAXyM2":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerDME9Uwz5k: React.ComponentType<Props> = withCSS(Component, css, "framer-DaXne") as typeof Component;
export default FramerDME9Uwz5k;

FramerDME9Uwz5k.displayName = "Hero Background Orbit";

FramerDME9Uwz5k.defaultProps = {height: 432, width: 1440};

addPropertyControls(FramerDME9Uwz5k, {variant: {options: ["c7gh2KS9W", "g8hHAXyM2"], optionTitles: ["Desktop", "Mobile"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerDME9Uwz5k, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})